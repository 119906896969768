
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoNcm from '@/servicos/Cadastros/ServicoNcm';

export default defineComponent({
  name: 'SelecionarNcm',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    ncmSelecionado: {
      type: String,
      default: '',
    },
    ignorarCodigoNcm: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: 'Digite para selecionar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:ncmSelecionado', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesComListaConsultaRapida } = useTelaBase();

    let debounce = 0;
    const servicoNcm = new ServicoNcm();

    const state = reactive({
      listaNcms: [] as IOption[],
      buscandoDados: false,
      emDigitacao: false,
    });

    const computedNcmSelecionado = computed({
      get: () => props.ncmSelecionado,
      set: (val: string) => {
        emit('update:ncmSelecionado', val);
      },
    });

    async function obterNcmPorCodigo(valor: any) {
      if (state.emDigitacao) { return; }
      if (!UtilitarioGeral.valorValido(valor)) { return; }
      if (!(valor > 0)) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else if (props.codigoSelecionado === 0) {
        return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaNcms)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaNcms.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaNcms.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaNcms = [];

      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        apenasAtivos: false, recursoAssociado: '',
      };

      if (props.varios) {
        parametrosConsultaRapida.filtrarPorVariosCodigos = true;
        parametrosConsultaRapida.valores = valor;
      } else {
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = valor;
      }

      const listaNcms = await servicoNcm.consultaRapida(parametrosConsultaRapida);
      state.listaNcms = montaOpcoesComListaConsultaRapida(listaNcms);
      if (!UtilitarioGeral.validaLista(listaNcms)) {
        if (props.varios) {
          emit('update:codigosSelecionados', undefined);
        } else {
          emit('update:codigoSelecionado', undefined);
        }
      }
    }

    async function pesquisarNcm(valorPesquisa: any) {
      state.emDigitacao = true;

      state.buscandoDados = true;
      state.listaNcms = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: valorPesquisa, apenasAtivos: true, recursoAssociado: '',
        };

        let listaNcms = await servicoNcm.consultaRapida(parametrosConsultaRapida);
        if (props.ignorarCodigoNcm > 0) {
          listaNcms = listaNcms.filter((c) => c.codigo !== props.ignorarCodigoNcm);
        }
        state.buscandoDados = false;
        state.listaNcms = montaOpcoesComListaConsultaRapida(listaNcms);
      }, 600);
    }

    function obterNcm(): any {
      obterNcmPorCodigo(props.codigoSelecionado);
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedCodigoSelecionado = computed({
      get: () => obterNcm(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
        const option = state.listaNcms.find((c) => c.value === val);
        if (option !== undefined) {
          const conteudo = option.label.split('-');
          computedNcmSelecionado.value = conteudo[0].trim();
        }
      },
    });

    function obterNcms(): number[] {
      obterNcmPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterNcms(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      state,
      pesquisarNcm,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
    };
  },
});
