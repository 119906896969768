
import { defineComponent } from 'vue';
import Icone from '../../components/Icone.vue';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';

export default defineComponent({
  name: 'MeusAtalhos',
  components: {
    Icone,
  },
  setup() {
    const { removerAtalho } = useTelaBase();

    function removerAtalhoEmpresaOperacao(identificadorRecurso: string): void {
      removerAtalho(identificadorRecurso);
    }
    return {
      storeSistema,
      removerAtalhoEmpresaOperacao,
    };
  },
});
