import ApiSistema from '@/core/conectores/ApiSistema';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTONotificacao } from '@/models/DTO/Sistema/Notificacoes/IDTONotificacao';
import { IParametrosNotificacao } from '@/models/DTO/Sistema/Notificacoes/IParametrosNotificacao';

/**
 * Serviço de Notificações
 * Fornece todas regras de negócios e lógicas relacionado as Notificações.
 */

class ServicoNotificacao {
  private endPoint = 'notificacoes';

  private apiSistema = new ApiSistema();

  public async obterQuantidadeNotificacoesUsuarioNaoLidas(): Promise<number> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/usuario/nao-lidas`);
    return result.data;
  }

  public async obterNotificacoesUsuario(parametrosNotificacao: IParametrosNotificacao): Promise<IDTONotificacao[]> {
    let parametrosAdicionais = '';

    if (parametrosNotificacao.tipo !== undefined) {
      parametrosAdicionais += `?Tipo=${parametrosNotificacao.tipo}`;
    }

    if (parametrosNotificacao.lidas !== undefined) {
      parametrosAdicionais += `&Lidas=${parametrosNotificacao.lidas}`;
    }

    if (parametrosNotificacao.pendentes !== undefined) {
      parametrosAdicionais += `&Pendentes=${parametrosNotificacao.pendentes}`;
    }

    if (parametrosNotificacao.codigoUltimaNotificacao !== undefined) {
      parametrosAdicionais += `&CodigoUltimaNotificacao=${parametrosNotificacao.codigoUltimaNotificacao}`;
    }

    if (parametrosNotificacao.quantidadeNotificacoes !== undefined) {
      parametrosAdicionais += `&QuantidadeNotificacoes=${parametrosNotificacao.quantidadeNotificacoes}`;
    }

    const result: any = await this.apiSistema.get(`${this.endPoint}/usuario${parametrosAdicionais}`);
    return result.data;
  }

  public async obterNotificacoesUsuarioBarraSuperior(): Promise<IDTONotificacao[]> {
    const parametrosNotificacao: IParametrosNotificacao = {} as IParametrosNotificacao;
    parametrosNotificacao.tipo = 0;
    parametrosNotificacao.lidas = false;
    parametrosNotificacao.pendentes = false;
    parametrosNotificacao.codigoUltimaNotificacao = 0;
    parametrosNotificacao.quantidadeNotificacoes = 10;
    const retorno = await this.obterNotificacoesUsuario(parametrosNotificacao);
    return retorno;
  }

  public async confirmarLeitura(codigoNotificacao: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.patch(`${this.endPoint}/${codigoNotificacao}/confirmar-leitura`);
    return result.data;
  }

  public async excluirTodasNotificacoes(): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.delete(`${this.endPoint}`);
    return result.data;
  }
}

export default ServicoNotificacao;
