
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import VinculoIntegracao from '@/components/Vinculo/VinculoIntegracao.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoCategoria from '@/servicos/Cadastros/ServicoCategoria';
import { ICategoria } from '@/models/Entidades/Cadastros/ICategoria';
import SelecionarDefinicaoPreco from '@/components/Cadastros/SelecionarDefinicaoPreco.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarCategoria from '@/components/Cadastros/SelecionarCategoria.vue';
import { EDefinicaoPreco } from '@/models/Enumeradores/EDefinicaoPreco';
import { ETipoVinculo } from '@/models/Enumeradores/ETipoVinculo';

export default defineComponent({
  name: 'CategoriaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
    SelecionarDefinicaoPreco,
    CampoNumerico,
    SelecionarCategoria,
    VinculoIntegracao,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);

    const servicoCategoria = new ServicoCategoria();
    telaBase.identificadorRecurso = 'CADASTRO_CATEGORIAS';

    const state = reactive({
      categoria: {} as ICategoria,
    });

    function objetoInicial() {
      state.categoria = {} as ICategoria;
      state.categoria.codigo = 0;
      state.categoria.definicaoPreco = EDefinicaoPreco.PrecoManualAnuncio;
      state.categoria.markup = 0;
      state.categoria.ativo = true;
      state.categoria.vinculos = [];
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();

      if (state.categoria.codigo === 0) {
        retorno = await servicoCategoria.incluir(state.categoria);
      } else {
        retorno = await servicoCategoria.alterar(state.categoria);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (state.categoria.codigo === 0) {
          sincronizarRegistro(retorno.codigoRegistro);
        } else {
          sincronizarRegistro(props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        defineTextoPadraoBotoes((props.operacao.codigoRegistro === 0), true);
        if (props.operacao.codigoRegistro > 0) {
          state.categoria = await servicoCategoria.obter(props.operacao.codigoRegistro);
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      ETipoVinculo,
    };
  },
});
