import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b488f97"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "ss-bolha-slot1" }, {
      default: _withCtx(() => [
        (_ctx.state.exibirBolhaProximo && _ctx.computedExibir)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "ss-bolha slot1",
              style: {"background-color":"#1890ff"},
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.avancarPaginaMenus(false)))
            }, [
              _createVNode(_component_icone, {
                class: "ss-icone-bolha slot1",
                nome: "navegacao-proximo"
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.menusPaginaAtual, (menu, index) => {
      return (_openBlock(), _createBlock(_Transition, {
        key: index,
        name: 'ss-bolha-slot' + (_ctx.state.exibirBolhaProximo ? (index + 2) : (index + 1))
      }, {
        default: _withCtx(() => [
          (_ctx.computedExibir)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass('ss-bolha slot' + (_ctx.state.exibirBolhaProximo ? (index + 2) : (index + 1))),
                style: _normalizeStyle('background-color: ' + menu.cor),
                onClick: ($event: any) => (_ctx.abrirMenuRecursos(menu.codigoModulo))
              }, [
                _createVNode(_component_icone, {
                  class: _normalizeClass('ss-icone-bolha slot' + (_ctx.state.exibirBolhaProximo ? (index + 2) : (index + 1))),
                  nome: menu.icone
                }, null, 8, ["class", "nome"])
              ], 14, _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["name"]))
    }), 128)),
    _createVNode(_Transition, {
      name: 'ss-bolha-slot' + (_ctx.state.exibirBolhaProximo ? _ctx.state.menusPaginaAtual.length + 2 : _ctx.state.menusPaginaAtual.length + 1)
    }, {
      default: _withCtx(() => [
        (_ctx.state.exibirBolhaAnterior && _ctx.computedExibir)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: _normalizeClass('ss-bolha slot' + (_ctx.state.exibirBolhaProximo ? _ctx.state.menusPaginaAtual.length + 2 : _ctx.state.menusPaginaAtual.length + 1)),
              style: {"background-color":"#1890ff"},
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.regredirPaginaMenus && _ctx.regredirPaginaMenus(...args)))
            }, [
              _createVNode(_component_icone, {
                class: _normalizeClass('ss-icone-bolha slot' + (_ctx.state.exibirBolhaProximo ? _ctx.state.menusPaginaAtual.length + 2 : _ctx.state.menusPaginaAtual.length + 1)),
                nome: "navegacao-anterior"
              }, null, 8, ["class"])
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["name"])
  ], 64))
}