
import {
  defineComponent, onBeforeMount, onMounted, ref, reactive,
} from 'vue';
import storeSistema from '@/store/storeSistema';
import GerenteAutenticacao from '../gerentes/GerenteAutenticacao';
import router from '@/router';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import Loading from './Loading.vue';
import Icone from '@/core/components/Icone.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import NotificacoesMobile from './Notificacoes/NotificacoesMobile.vue';
import MeusAtalhosMobile from './MeusAtalhos/MeusAtalhosMobile.vue';
import MenuMobileBolhas from './Menu/MenuMobileBolhas.vue';
import MenuMobileGrade from './Menu/MenuMobileGrade.vue';
import { obterItem, salvarItem } from '@/core/gerentes/GerenteLocalStorage';
import ServicoSistema from '@/servicos/Sistema/ServicoSistema';
import { EStatusContratante } from '@/models/Enumeradores/EStatusContratante';
import { ETipoApresentacaoMenu } from '@/models/Enumeradores/ETipoApresentacaoMenu';
import { ETipoMenuItem } from '@/models/Enumeradores/ETipoMenu';
import { IDTODadosContratante } from '@/models/DTO/Sistema/Contratantes/IDTODadosContratante';

export default defineComponent({
  name: 'AppLayout',
  components: {
    Loading,
    Icone,
    NotificacoesMobile,
    MeusAtalhosMobile,
    MenuMobileGrade,
    MenuMobileBolhas,
  },
  props: {
    msg: String,
  },
  setup() {
    storeSistema.mutations.atualizarCarregando(true);
    const gerenteAutenticacao = new GerenteAutenticacao();
    const collapsed = ref(false);
    const exibirConfiguracoes = ref(false);
    const exibirTributacoes = ref(false);

    let cacheMenu = 0;

    const state = reactive({
      menuGrade: true,
      menuSelecionado: 0,
      exibirModulos: false,
      exibirMenuRecursos: false,
      exibirNotificacoes: false,
      exibirFavoritos: false,
    });

    const handleWindowSizeChange = () => {
      storeSistema.mutations.atualizarLarguraTela();
    };

    onMounted(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      handleWindowSizeChange();
    });

    function obterTipoMenu() {
      state.menuGrade = obterItem(`USAR_MENU_GRADE_${storeSistema.getters.codigoUsuarioAutenticado}`) as boolean;
    }

    onBeforeMount(async () => {
      if (!gerenteAutenticacao.existeToken()) {
        router.push({ name: 'Login' });
      }

      if (gerenteAutenticacao.atualizarToken()) {
        const retornoAutenticacao = await new ServicoSistema().atualizarToken();
        if (retornoAutenticacao.autenticado) {
          gerenteAutenticacao.salvarDadosAutenticacao(retornoAutenticacao.token, retornoAutenticacao.dataExpiracao.toString());
        }
      }

      obterTipoMenu();
      await new ServicoSistema().preencherDadosSistema();
      if (storeSistema.state.contratantesDisponiveis === null || storeSistema.state.contratantesDisponiveis === undefined) {
        router.push({ name: 'ErroRequisicao' });
      }

      if (storeSistema.state.contratanteEmOperacao.status !== EStatusContratante.Ativo) {
        router.push({ name: 'AssinaturaInativa' });
      }

      storeSistema.mutations.atualizarCarregando(false);
    });

    function exibirFullScreen() {
      document.body.classList.toggle('fullscreen-enable');
      if (
        !document.fullscreenElement
      ) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        }
      } else {
        document.exitFullscreen();
      }
    }

    function atualizarCollapsed() {
      collapsed.value = !collapsed.value;
      storeSistema.mutations.atualizarMenuCollapse();
    }

    function montarApresentacaoContratante(dadosContratante: IDTODadosContratante) {
      const exibicao = UtilitarioGeral.montaExibicaoContratante(dadosContratante.nomeRazaoSocial, dadosContratante.nomeFantasia);
      const textoCpfouCnpj = UtilitarioGeral.documentoIdentificacaoCPFouCNPJ(dadosContratante.cpfCnpj);
      const cpfCpnpj = UtilitarioMascara.mascararCPFOuCNPJ(dadosContratante.cpfCnpj);

      return `${exibicao} | ${textoCpfouCnpj}: ${cpfCpnpj}`;
    }

    async function deslogar() {
      const servicoSistema = new ServicoSistema();
      const gerenteAutenticacao = new GerenteAutenticacao();
      const retornoAutenticacao = await servicoSistema.deslogar();
      if (!retornoAutenticacao.autenticado) {
        gerenteAutenticacao.removerDadosAutenticacao();
        router.push({ name: 'Login' });
      }
    }

    function alterarTipoMenu() {
      clearTimeout(cacheMenu);
      cacheMenu = setTimeout(async () => {
        await salvarItem(`USAR_MENU_GRADE_${storeSistema.getters.codigoUsuarioAutenticado}`, state.menuGrade);
      }, 600);
    }

    function abrirMenuModulos() {
      state.exibirModulos = !state.exibirModulos;
      state.exibirMenuRecursos = false;
    }

    function abrirMenuRecursos(menu: number) {
      state.menuSelecionado = menu;
      state.exibirModulos = false;
      state.exibirMenuRecursos = true;
      state.exibirNotificacoes = false;
      state.exibirFavoritos = false;
    }

    function abrirNotificacoes() {
      state.exibirModulos = false;
      state.exibirMenuRecursos = false;
      state.exibirFavoritos = false;
      state.menuSelecionado = 0;
    }

    function abrirFavoritos() {
      state.exibirModulos = false;
      state.exibirMenuRecursos = false;
      state.exibirNotificacoes = false;
      state.menuSelecionado = 0;
    }

    function fecharMenuRecursos() {
      state.exibirModulos = false;
      state.exibirMenuRecursos = false;
      state.exibirNotificacoes = false;
      state.exibirFavoritos = false;
      state.menuSelecionado = 0;
    }

    function voltarMenuModulos() {
      state.exibirModulos = true;
      state.exibirMenuRecursos = false;
      state.exibirNotificacoes = false;
      state.exibirFavoritos = false;
      state.menuSelecionado = 0;
    }

    return {
      storeSistema,
      current: ['1'],
      exibirFullScreen,
      ETipoApresentacaoMenu,
      ETipoMenuItem,
      collapsed,
      state,
      UtilitarioGeral,
      exibirConfiguracoes,
      exibirTributacoes,
      deslogar,
      montarApresentacaoContratante,
      atualizarCollapsed,
      alterarTipoMenu,
      abrirMenuModulos,
      abrirMenuRecursos,
      abrirNotificacoes,
      abrirFavoritos,
      fecharMenuRecursos,
      voltarMenuModulos,
    };
  },
});
