
import { defineComponent, onBeforeMount, reactive } from 'vue';
import Icone from '../../components/Icone.vue';
import storeSistema from '@/store/storeSistema';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import router from '@/router';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTONotificacao } from '@/models/DTO/Sistema/Notificacoes/IDTONotificacao';
import ServicoNotificacao from '@/servicos/Sistema/ServicoNotificacao';
import { ETipoNotificacao } from '@/models/Enumeradores/ETipoNotificacao';

// Componente utilizado Barra superior do layout: ERP
export default defineComponent({
  components: {
    Icone,
  },
  name: 'Notificacoes',
  setup() {
    const servicoNotificacao = new ServicoNotificacao();
    const state = reactive({
      notificacoes: [] as IDTONotificacao[],
      buscandoNotificacoes: false,
      exibirNotificacoes: false,
    });

    async function obterNotificacoesAtualizadas() {
      state.exibirNotificacoes = true;
      state.buscandoNotificacoes = true;

      state.buscandoNotificacoes = false;
    }

    onBeforeMount(async () => {
      state.notificacoes = await servicoNotificacao.obterNotificacoesUsuarioBarraSuperior();
    });

    async function acessarLinkNotificacao(notificacao: IDTONotificacao) {
      state.exibirNotificacoes = false;

      if (!notificacao.lida) {
        const retornoConfirmacao = await new ServicoNotificacao().confirmarLeitura(notificacao.codigo);
        if (retornoConfirmacao.status === EStatusRetornoRequisicao.Sucesso) {
          storeSistema.mutations.diminuirNotificacaoNaoLida();
        }
      }

      if (UtilitarioGeral.valorValido(notificacao.parametros)) {
        router.push({ path: `/${notificacao.rotaRecurso}${notificacao.parametros}` });
      } else {
        router.push({ path: `/${notificacao.rotaRecurso}` });
      }
    }
    function obterCorNotificacao(tipo: number): string {
      let cor = '#2196f3';
      if (tipo === ETipoNotificacao.Normal) {
        cor = '#2196f3';
      } else if (tipo === ETipoNotificacao.Importante) {
        cor = '#ffc107';
      } else if (tipo === ETipoNotificacao.Urgente) {
        cor = '#f44336';
      }
      return cor;
    }

    function visualizarTodasNotificacoes() {
      state.exibirNotificacoes = false;
      router.push({ name: 'Notificacoes' });
    }
    function obterAbreviacaoRecurso(recurso: string): string {
      let abreviacao = '';
      if (UtilitarioGeral.valorValido(recurso)) {
        abreviacao = recurso.length > 3 ? recurso.substring(0, 3) : recurso.substring(0, 1);
      }
      return abreviacao;
    }
    return {
      storeSistema,
      state,
      obterNotificacoesAtualizadas,
      obterCorNotificacao,
      obterAbreviacaoRecurso,
      acessarLinkNotificacao,
      visualizarTodasNotificacoes,
      UtilitarioData,
    };
  },
});
