
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import ColunaGrade from '@/core/components/Tela/ColunaGrade.vue';
import Paginacao from '@/core/components/Tela/Paginacao.vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import PreferenciasColuna from '@/core/components/Preferencias/ColunaTabela.vue';
import NcmModal from './NcmModal.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ECustomRenderRow, IColumn } from '@/core/models/AntDesign/IColumn';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { useCrudBase } from '@/core/composables/CrudBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import { INcm } from '@/models/Entidades/Cadastros/INcm';
import ServicoNcm from '@/servicos/Cadastros/ServicoNcm';
import ServicoSistema from '@/servicos/Sistema/ServicoSistema';

export default defineComponent({
  name: 'NcmIndex',
  components: {
    TituloPadrao,
    ColunaGrade,
    Paginacao,
    Icone,
    Card,
    MensagemSemDados,
    PreferenciasColuna,
    NcmModal,
    BuscaGeral,
  },
  setup() {
    const {
      telaBase, preencherDadosRota, telaOperacaoIncluir, telaOperacaoEditar, apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
    } = useTelaBase();

    const { crudBase, exibirModal } = useCrudBase();

    const {
      gradeBase, salvarFiltrosBuscaAvancada, salvarOrdenacaoBuscaAvancada, salvarPersonalizacaoColunas, redefinirPersonalizacaoColunas,
      carregarPreferenciasGrade, preparaParametrosConsulta, salvarTodasPreferencias, preencheCodigosSelecionados,
    } = useGradeBase();

    const servicoNcm = new ServicoNcm();
    telaBase.contratantesSelecionados = [];
    gradeBase.ordenacaoSelecionada = [];

    gradeBase.colunasPadrao = [
      {
        title: 'NCM', dataIndex: 'codigoNcm', key: 'CodigoNcm', position: 0, visible: true, ordering: true, align: 'left', width: 150,
      },
      {
        title: 'Descrição', dataIndex: 'descricao', key: 'DescricaoNcm', position: 1, visible: true, ordering: true, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Ações', key: 'acoes', position: 2, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];

    gradeBase.colunasMobile = [
      {
        title: 'Ncm', dataIndex: '', key: 'Ncm', position: 0, visible: true, align: 'left',
      },
    ];

    const state = reactive({
      dados: [] as INcm[],
      buscaRapida: '',
      exibirPersonalizarColunas: false,
      exibirBuscaAvancada: false,
      exibirPersonalizacaoTela: false,
    });

    async function buscarDados() {
      state.dados = [];
      const parametrosConsulta = preparaParametrosConsulta(telaBase.contratantesSelecionados, gradeBase.paginacao.current, gradeBase.paginacao.pageSize, gradeBase.paginacao.total);
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      telaBase.carregando = true;

      const listaNcmPaginado = await servicoNcm.buscaAvancada(parametrosConsulta, gradeBase.filtrosAplicados);
      state.dados = listaNcmPaginado.dados;
      gradeBase.paginacaoApi = listaNcmPaginado.metaData;
      telaBase.carregando = false;
    }

    async function imprimir(tipoArquivoRelatorio: ETipoArquivo, codigoRegistro?: number) {
      gradeBase.filtrosAplicados = [];
      const parametrosConsulta = preparaParametrosConsulta(telaBase.contratantesSelecionados, 1, 0, 0);
      let codigosSelecionados: number[] = [];
      if (codigoRegistro !== undefined && codigoRegistro > 0) {
        codigosSelecionados.push(codigoRegistro);
      } else {
        codigosSelecionados = gradeBase.codigosSelecionados;
      }

      telaBase.carregando = true;
      const retornoRelatorio = await servicoNcm.relatorioPadrao(tipoArquivoRelatorio, parametrosConsulta, gradeBase.filtrosAplicados, codigosSelecionados);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        window.open(retornoRelatorio.link, '_blank');
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function carregarPreferencias() {
      telaBase.preferencias = await new ServicoSistema().obterPreferenciaRecurso(telaBase.identificadorRecurso, telaBase.contratantesSelecionados);
      if (telaBase.preferencias !== null) {
        carregarPreferenciasGrade(telaBase.preferencias);
      }
    }

    onBeforeMount(async () => {
      preencherDadosRota();

      telaBase.propriedadesConsulta = await servicoNcm.obterPropriedadesConsulta();
      if (telaBase.propriedadesConsulta.length > 0) {
        gradeBase.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        gradeBase.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      gradeBase.buscaAvancada.filtrosAdicionados = [];
      gradeBase.buscaAvancada.chaveFiltrosAdicionados = 0;
      await carregarPreferencias();
      await buscarDados();
    });

    function novoNcm() {
      exibirModal(telaOperacaoIncluir());
    }

    function editarNcm(codigo: number) {
      exibirModal(telaOperacaoEditar(codigo));
    }

    async function excluirNcm(codigo: number) {
      const retornoExclusao = await servicoNcm.excluir(codigo);
      if (retornoExclusao.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retornoExclusao.mensagem);
        state.dados = state.dados.filter((ncm: INcm) => ncm.codigo !== codigo);
      } else if (retornoExclusao.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível concluir a exclusão!',
          content: retornoExclusao.mensagem,
        });
      }
    }

    async function confirmaExclusao(ncm: INcm) {
      Modal.confirm({
        title: 'Você confirma a exclusão do NCM:',
        content: `${ncm.codigoNcm} - ${ncm.descricao}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await excluirNcm(ncm.codigo); },
      });
    }

    async function sincronizarNcm(codigo: number) {
      const parametrosConsulta: IParametrosConsulta = {} as IParametrosConsulta;

      if (!UtilitarioGeral.validaLista(telaBase.contratantesSelecionados)) {
        telaBase.contratantesSelecionados = storeSistema.getters.contratantesUsuarioAutenticado();
      }

      parametrosConsulta.contratantes = telaBase.contratantesSelecionados;
      parametrosConsulta.codigosSelecionados = [];

      parametrosConsulta.codigosSelecionados.push(codigo);
      const resultadoBusca = await servicoNcm.buscaAvancada(parametrosConsulta);
      if (UtilitarioGeral.validaLista(resultadoBusca.dados)) {
        const index = state.dados.findIndex((c) => c.codigo === codigo);
        if (index >= 0) {
          state.dados[index] = resultadoBusca.dados[0];
        } else {
          state.dados.push(resultadoBusca.dados[0]);
        }
      }
    }

    function exibirApenasParaAdministrador(): boolean {
      return storeSistema.getters.usuarioAutenticadoAdministrador();
    }

    function apresentarBuscaAvancada() {
      state.exibirPersonalizarColunas = false;
      state.exibirBuscaAvancada = !state.exibirBuscaAvancada;
    }

    function apresentarPersonalizarColuna() {
      state.exibirBuscaAvancada = false;
      state.exibirPersonalizarColunas = !state.exibirPersonalizarColunas;
    }

    function apresentarPersonalizacaoTela() {
      state.exibirPersonalizacaoTela = true;
    }

    async function aplicarFiltros() {
      gradeBase.paginacao.current = 1;
      await buscarDados();
    }

    function textoCodigosSelecionados(): string {
      if (gradeBase.codigosSelecionados.length === 1) { return '1 - NCM selecionado'; }

      if (gradeBase.codigosSelecionados.length > 1) { return `${gradeBase.codigosSelecionados.length} - NCMs selecionados`; }

      return '';
    }

    function obtemDataIndex(coluna: IColumn) {
      if (coluna.dataIndex !== undefined) {
        return coluna.dataIndex;
      }
      return '';
    }
    return {
      storeSistema,
      telaBase,
      gradeBase,
      crudBase,
      state,
      novoNcm,
      editarNcm,
      confirmaExclusao,
      excluirNcm,
      sincronizarNcm,
      apresentarBuscaAvancada,
      apresentarPersonalizarColuna,
      apresentarPersonalizacaoTela,
      aplicarFiltros,
      buscarDados,
      imprimir,
      ECustomRenderRow,
      EVinculoEmpresa,
      ETipoArquivo,
      salvarFiltrosBuscaAvancada,
      salvarOrdenacaoBuscaAvancada,
      salvarPersonalizacaoColunas,
      salvarTodasPreferencias,
      redefinirPersonalizacaoColunas,
      preencheCodigosSelecionados,
      textoCodigosSelecionados,
      obtemDataIndex,
      exibirApenasParaAdministrador,
    };
  },
});
