
import { defineComponent, ref } from 'vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import CaracteristicaModal from '@/views/Cadastros/Caracteristicas/CaracteristicaModal.vue';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import ServicoCaracteristica from '@/servicos/Cadastros/ServicoCaracteristica';

export default defineComponent({
    name: 'Caracteristica',
    components: {
        TelaPadraoCrud,
        CaracteristicaModal,
    },
    setup() {
        const {
            crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud, preencherCamposOrdenacao,
        } = useCrudBase();
        const servicoCaracteristica = new ServicoCaracteristica();
        const refCrud = ref<InstanceType<typeof TelaPadraoCrud>>();

        crudBase.estrutura = preencherEstrutura();
        crudBase.estrutura.apresentarEmpresas = true;
        crudBase.estrutura.verificarCadastroCompartilhado = true;
        crudBase.estrutura.botaoPrincipalAcao = 'Nova';
        crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão da característica:';
        crudBase.estrutura.colunasGrade = [
            {
                title: 'Código', dataIndex: 'codigo', key: 'CodigoCaracteristica', position: 0, visible: false, ordering: true,
            },
            {
                title: 'Nome', dataIndex: 'nome', key: 'NomeCaracteristica', position: 1, visible: true, ordering: true, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
            },
            {
                title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInternoCaracteristica', position: 2, visible: true, ordering: true, align: 'left', width: 150,
            },
            {
                title: 'Status', dataIndex: 'ativo', key: 'StatusCaracteristica', position: 3, visible: true, align: 'left', width: 100, customRenderRow: ECustomRenderRow.TagAtiva,
            },
            {
                title: 'Ações', key: 'acoes', position: 4, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
            },
        ];
        crudBase.estrutura.ordenacaoPadraoGrade = preencherCamposOrdenacao();

        async function sincronizarRegistro(codigoRegistro: number) {
            if (refCrud.value !== undefined) {
                await refCrud.value.sincronizarRegistro(codigoRegistro);
            }
        }

        return {
            crudBase,
            servicoCaracteristica,
            refCrud,
            exibirModal,
            sincronizaTelaCrud,
            sincronizarRegistro,
        };
    },
});
